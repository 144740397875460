import React, { useEffect, useState } from 'react'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../Assets/Images/eu.png'
import userImage from '../Assets/user.png'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useNavigate,useLocation } from 'react-router-dom';
import { BASE_URL,IMAGE_BASE_URL } from "../API";

function Header({userDetails}) {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")))
    let token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    const logOut = () => {
        localStorage.clear();
    };
    const moveToProfile =  () =>{
        navigate("/myprofile");
    }
    useEffect(()=>{
        setUser(JSON.parse(localStorage.getItem("user")));
    },[userDetails])
    useEffect(()=>{
        // if(!localStorage.getItem('user') && !localStorage.getItem('token') && location.pathname !== '/' ){
        //     navigate("/login");
        // }
    },[])
    return (
        <>
            <Navbar expand="lg" className="bg-body-tertiary">
                <div className="th-header header-layout1">
                    <div className="sticky-wrapper">
                        <div className="menu-area">
                            <Container className='d-flex cont-head-main'>
                                <Navbar.Brand href="/">
                                    <img src={logo} alt="" />
                                </Navbar.Brand>
                                <Navbar.Toggle aria-controls="navbarScroll" />
                                <Navbar.Collapse id="navbarScroll">
                                    <Nav
                                        className="ms-auto my-2 my-lg-0 d-flex main-flex-head"
                                    >
                                        <Nav.Link className={ location.pathname === '/' ? 'home-line-had active' : 'home-line-had'} onClick={()=>navigate('/')}  >Home</Nav.Link>
                                        <Nav.Link className={ location.pathname === '/game' ? 'home-line-had active' : 'home-line-had'} onClick={()=>navigate('/game')} >Games</Nav.Link>
                                        <Nav.Link className={ location.pathname === '/tournament' ? 'home-line-had active' : 'home-line-had'} onClick={()=>navigate('/tournament')} >Tournaments</Nav.Link>
                                        
                                        {/* <Link to='/game' className='home-line-had nav-link'>Games</Link> */}
                                        {/* <Link to="/tournament" className='home-line-had nav-link'>Tournaments</Link> */}
                                        {/* <Nav.Link className='home-line-had'>Contact Us</Nav.Link> */}

                                    </Nav>
                                    {
                                        !token ?
                                            <Form className="d-flex mai-form-head">
                                                <div className="col-auto d-xl-block">
                                                    <div className="header-button d-flex">
                                                        <Link to="/login" className='login-head'>Login</Link>
                                                        <div className="d-xxl-block ">
                                                            <Link to="/signup" className="th-btn th_btn">REGISTER NOW</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Form>
                                            :
                                            <div className="d-flex name-upper-profile" onClick={()=>moveToProfile()}>
                                                <figure className='img-login'>
                                                    <img src={user?.user_details?.profile_picture ? IMAGE_BASE_URL + user?.user_details?.profile_picture : userImage}  alt="" />
                                                </figure>
                                                
                                                <div className="name-head">
                                                    <p>{user?.name}</p>
                                                </div>
                                            </div>
                                    }

                                </Navbar.Collapse>
                            </Container>
                            <div className="logo-bg">
                            </div>
                        </div>
                    </div>
                </div>
            </Navbar>
        </>
    )
}

export default Header
