import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import sigimg from "../Assets/signup.png";
import Form from "react-bootstrap/Form";
import { useForm } from "react-hook-form";
import { BASE_URL, EMAIL_RESET } from "../API";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Resetpassword() {
  const navigate = useNavigate();
  const [isLoggedIn, setLoggedIn] = useState(() => {
    const token = localStorage.getItem('token');
    return !!token;
  });
  useEffect(()=>{
    if(isLoggedIn){
      navigate('/');
    }
  },[]);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    localStorage.setItem("email_reset", data.email);

    try {
      const response = await axios.post(
        BASE_URL + EMAIL_RESET,
        data
      );

      if (response.data.code === 200) {
        toast.success(response.data.success);
        reset();
        navigate("/otp");
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
      }
      reset();
    }
  };

  return (
    <>
      <section className="signup-main-clas">
        <Row className=" g-0">
          {/* <Col md={6}>
            <div className="inner-img-sign login-page-clas">
              <figure>
                <img src={sigimg} className="img-fluid" alt="" />
              </figure>
            </div>
          </Col> */}
          <Col md={6}>
            <div className="upper-flex-signup reset-password">
              <div className="inner-text-fields">
                <div className="create-acc-sec">
                  <h2>
                    Lost your password? <br />
                    Please enter your username or email address. <br />
                    You will receive a link to create a new password via email.
                  </h2>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                      className="input-comn-styel-signup"
                      controlId="formGroupEmail">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter your Email Address"
                        {...register("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      {errors.email && (
                        <span className="text-danger">
                          {errors.email.message}
                        </span>
                      )}
                    </Form.Group>
                    <button className="get-start-btn-comn mt-1" type="submit">
                      Reset Password
                    </button>
                  </Form>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
}

export default Resetpassword;
