import React, { useState, useEffect, useRef, useCallback } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import redlogo from "../Assets/Images/redogo.png";
import backi from "../Assets/Images/bacr.png";
import VIP from "../Assets/Images/VIP.jpg";
import logcomp from "../Assets/Images/eu.png";
import Pagination from 'react-bootstrap/Pagination';


import {
  GET_TOKEN_PRICE,
  BASE_URL,
  IMAGE_BASE_URL,
  GET_TOKEN_TRANSACTION,
  SAVE_TOKEN_TRANSACTION,
} from "../API";
import { useNavigate } from "react-router-dom";
import Loader from "./Loader";
import axios from "axios"; // Make sure axios is imported
import userImage from "../Assets/user.png";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
const ITEMS_PER_PAGE = 5;

const PayPalConfig = {
  "client-id":
    "AftyrcHcbyn1yHRLbRiHCGFjRrtyQKt3TmOjvtNWsJVw89Ualqe5pNPtuQ1Q9JsZ77iMmwhYFoXMvT_W",
  "currency": "GBP"
};

function VIP_Token({ userDetails, getUserDetails, openModel }) {
  console.log(userDetails.user_details.subscription)
  const navigate = useNavigate();
  const paypalKeyRef = useRef(0); // Ref to hold key
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [selectedPackage, setSelectedPackage] = useState();
  const [creditPurchaseHistory, setCreditPurchaseHistory] = useState([]);

  const [show, setShow] = useState(false);
  const [key, setKey] = useState(paypalKeyRef.current);
  const [quantity, setQuantity] = useState(1);
  const [tokenPrice, setTokenPrice] = useState();
  const [totalAmount, setTotalAmount] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [error, setError] = useState(null);
  const { handleSubmit: handleSubmit, register, formState: { errors, reset } } = useForm();

  // const handlePackageSelect = (data) => {
  //   setSelectedPackage(data); 
  //   console.log(data)
  // };
  const resetPaypalKey = () => {
    paypalKeyRef.current += 1;
  };

  useEffect(() => {
    setKey(`paypal-${quantity}`);
  }, [quantity]);


  useEffect(() => {
    getTokenPurchesHistory();
    getTokenPrice();
  }, [userDetails]);

  useEffect(() => {
    getTokenPurchesHistory();
  }, [currentPage]);

  useEffect(() => {
    resetPaypalKey()
  }, [selectedPackage]);

  const getTokenPrice = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const user = JSON.parse(localStorage.getItem("user"));
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(BASE_URL + GET_TOKEN_PRICE + "?user_id=" + user.id, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        console.log(response.data.token_price)
        setTokenPrice(Number(response.data.token_price));
        setIsLoading(false);
        setTotalAmount(Number(response.data.token_price))

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const createOrder = (data, actions) => {
    let amount = totalAmount || 0;
    console.log(totalAmount)
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: amount,
            currency_code: "GBP",
            breakdown: {
              item_total: {
                currency_code: "GBP",
                value: amount,
              },
            },
          },

          items: [
            {
              unit_amount: {
                currency_code: "GBP",
                value: amount,
              },
              quantity: "1",
              name:
                "GBP " +
                amount +
                " Tokens " +
                amount,
            },
          ],
        },
      ],
    });
  };


  const onApprove = (data, actions) => {
    return actions.order
      .capture()
      .then(async function (details) {
        if (details && details.status === "COMPLETED") {
          const formData = {
            transaction_id: details.id,
            transaction_status: details.status,
            amount: details.purchase_units[0].amount.value,
            payment_type: data.paymentSource,
            token: quantity,
          };
          setIsLoading(true);
          try {
            const token = localStorage.getItem("token");
            const headers = {Authorization: `Bearer ${token}`,"Content-Type": "application/json",};
           const response = await axios.post(BASE_URL + SAVE_TOKEN_TRANSACTION,JSON.stringify(formData),{ headers: headers });
            if (response.data.status_code === 200 &&response.data.status === true) {
              setIsLoading(false);
              toast.success(response.data.message);
              setShow(false);
              getUserDetails();
            } else if (
              response.data.status_code === 400 &&
              response.data.status === false
            ) {
              toast.error(response?.data?.error[0]);
              setIsLoading(false);
            } else if (
              response.data.status_code === 403 &&
              response.data.status === false
            ) {
              localStorage.removeItem("user");
              localStorage.removeItem("token");
              navigate("/login");
            } else {
              // console.log('response.data::',response)
              toast.error(response.data.message);
            }
          } catch (error) {
            if (error.response && error.response.data) {
              const errorMessage =
                error.response.data.message || "An error occurred";
              setIsLoading(false);
              // toast.error(errorMessage);
            } else {
              // toast.error("An error occurred");
              setIsLoading(false);
            }
          }
        } else {
        }
      })
      .catch((error) => {
        // alert('error')
        console.error("Error occurred during payment approval:", error);
      });
  };

  const getTokenPurchesHistory = async () => {
    setIsLoading(true);
    try {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.get(BASE_URL + GET_TOKEN_TRANSACTION, {
        headers: headers,
      });

      if (response.data.status_code === 200 && response.data.status === true) {
        const purchaseHistory = response.data.data;
        // console.log(purchaseHistory)
        const totalCount = purchaseHistory.length;
        setTotalPages(Math.ceil(totalCount / ITEMS_PER_PAGE));
        const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
        const currentItems = purchaseHistory.slice(startIndex, startIndex + ITEMS_PER_PAGE);
        setCreditPurchaseHistory(currentItems);
        setIsLoading(false);

        //   toast.success(response.data.message);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        navigate("/login");
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        setIsLoading(false);
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const onError = (err) => {
    setError(err)
  };

  const netAmount = (qty) => {
    if (qty > 0) {
      const result = qty * tokenPrice;
      console.log(result)
      setTotalAmount(result)
    }
  }

  const handleQuantityChange = (change) => {
    setQuantity((prevQuantity) => {
      const newQuantity = prevQuantity + change;
      return newQuantity > 0 ? newQuantity : 1; // Ensure quantity is at least 1
    });
  };

  return (
    <>
      <Loader isLoading={isLoading} />

      <section>
        <Container>
          <div className="edit-profile-main ">
            <h3 className="main-head-pro">VIP Tokens </h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal d-flex">
                <div className="credit-inner-mg-pan">
                  <figure>
                    <img src={backi} alt="" />
                  </figure>
                  <div className="icon-cred-avail">
                    <img className="logo-plan" src={logcomp} alt="" />
                    <div className="cred-avl">
                      <p>Tokens Available</p>
                      <h6>{userDetails?.user_details?.token || 0}</h6>
                    </div>
                  </div>
                </div>

                <div className="cred-instruction w-100">
                  <div className="upper-head-w-logo d-flex">
                    <h6>Tokens Instructions</h6>
                    <img src={redlogo} alt="" />
                  </div>
                  <ul className="cred-list">
                    <li>
                      1 Token = £{tokenPrice} 
                    </li>
                    <li>
                      These tokens can be used to join VIP tournament only.
                    </li>
                    <li>
                      All members should have VIP subscription and token to join VIP tournament.
                    </li>
                    {/* <li>
                      Only VIP subscription user can buy the VIP Token.
                    </li>
                    <li>
                      No limit to buy VIP tokens, user can by multiple tokens.
                    </li>
                    <li>
                      Token will be a life time but for the utilisation user need to have VIP subscription plan active.
                    </li>
                    <li>
                      User can check the transaction for the buy tokens.
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className="upper-btn-login-page text-start mt-4">
                {userDetails.user_details.subscription != 0 ?
                  <button className="edit-butt" onClick={() => setShow(true)}>
                    Buy Tokens
                  </button> : <><p style={{ color: 'white' }}>You are not a VIP subscriber</p>  <button className="edit-butt" onClick={() => navigate('/myprofile', { state: { menu: 5 } })}>
                    Upgrade Now
                  </button></>}
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="edit-profile-main mt-4">
            <h3 className="main-head-pro">Tokens History</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal">
                <div className="upper-my-tournament-table">
                  <table className="w-100">
                    <tr>
                      <th>Transacton ID</th>
                      <th>Payment Method</th>
                      <th>Date</th>
                      <th>Time</th>
                      <th>Total Tokens</th>
                      <th>Paid Amount</th>
                    </tr>
                    {creditPurchaseHistory && creditPurchaseHistory.length ? (
                      creditPurchaseHistory.map((element, key) => {
                        return (
                          <tr key={key}>
                            <td>#{element.transaction_id}</td>
                            <td>{element.payment_type} </td>
                            <td>{moment(element.created_at).format('DD-MM-YYYY')}</td>
                            <td>{moment(element.created_at).format('HH:mm')}</td>
                            <td>{element.total_tokens}</td>
                            <td>£ {element.amount}</td>
                          </tr>
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      {creditPurchaseHistory && creditPurchaseHistory.length > 0 ?
        <Pagination>
          <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage == 1} />
          <Pagination.Prev onClick={() => handlePageChange(Math.max(currentPage - 1, 1))} disabled={currentPage == 1} />
          {Array.from({ length: totalPages }).map((_, index) => (
            <Pagination.Item
              key={index + 1}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
          <Pagination.Next onClick={() => handlePageChange(Math.min(currentPage + 1, totalPages))} disabled={currentPage == totalPages} />
          <Pagination.Last onClick={() => handlePageChange(totalPages)} disabled={currentPage == totalPages} />
        </Pagination> : <></>}
      <Modal show={show} className="modal-logout" onHide={handleClose}>
        <Modal.Header className="border-0 pb-0" closeButton>

        </Modal.Header>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Buy Tokens</h2>
            {/* <p className="text-damble-text">
              This transaction is non refundable, Are you sure you want to continue?
            </p> */}
          </div>
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-lg-6">
                <Form.Group className="input-comn-styel-signup" controlId="formGroupQuantity">
                  <Form.Label className='label-clas-comon'>
                    Quantity<span>*</span>
                  </Form.Label>
                  <div className="quantity-control d-flex align-items-center">
                    <Button
                      type="button"
                      className="btn-minus"
                      onClick={(event) => { handleQuantityChange(-1); netAmount(quantity - 1) }}
                    >
                      -
                    </Button>
                    <span className='quantity-value'>{quantity}</span>
                    <Button
                      type="button"
                      className="btn-plus"
                      onClick={(event) => { handleQuantityChange(1); netAmount(quantity + 1) }}
                    >
                      +
                    </Button>
                  </div>
                </Form.Group>
              </div>
              {/* <Button className='login-btn' type="submit">Submit</Button> */}
              {totalAmount !== null && (
                <div className="col-lg-6">
                  <div className="mt-3 main-token">
                    {/* <p> <span>Token Price:</span> ${tokenPrice}</p>
                <p><span>Quantity:</span> {quantity}</p> */}
                    <p><span>Total Price:</span>£{tokenPrice * quantity}</p>
                  </div>
                </div>
              )}
            </div>
          </Form>
          <div className="upper-payment-main">
            {tokenPrice && (
              <PayPalScriptProvider key={key} options={PayPalConfig}>
                <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} />
              </PayPalScriptProvider>
            )}
          </div>
        </Modal.Body>
      </Modal>

    </>
  );
}

export default VIP_Token;
