import React, { useState, useEffect, useRef } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import redlogo from "../Assets/Images/redogo.png";
import backi from "../Assets/Images/bacr.png";
import logcomp from "../Assets/Images/eu.png";
import Pagination from 'react-bootstrap/Pagination';


import {
  CREDITS_PACKAGE,
  BASE_URL,
  IMAGE_BASE_URL,
  USER_CREDIT_TRANSACTIONS,
  SAVE_TRANSACTION,
  UPDATE_PAYPAL_EMAIL,
  WITHDRAW_MONEY,
  USER_WITHDRAWAL_MONEY_REQUEST
} from "../API";
import { useNavigate } from "react-router-dom";
import Loader from "../components/Loader";
import axios from "axios";
import userImage from "../Assets/user.png";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import moment from "moment";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from '@fortawesome/free-solid-svg-icons';

const ITEMS_PER_PAGE = 5;

const PayPalConfig = {
  "client-id":
    "AftyrcHcbyn1yHRLbRiHCGFjRrtyQKt3TmOjvtNWsJVw89Ualqe5pNPtuQ1Q9JsZ77iMmwhYFoXMvT_W",
  "currency": "GBP"
};

function Wallet({ userDetails, getUserDetails, openModel }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [handlePaypalShow, setHandlePaypalShow] = useState(false);
  const [withDrawalData, setWithDrawalData] = useState();


  // const { handleSubmit, register, formState: { errors }} = useForm();
  const { handleSubmit: handleSubmit1, register: register1, formState: { errors: errors1 }, reset: reset1, setValue: setValue } = useForm();
  const { handleSubmit: handleSubmit2, register: register2, formState: { errors: errors2 }, reset: reset2 } = useForm();

  const onSubmitPaypalEmail = async (data) => {
    setIsLoading(true)
    try {
      const token = localStorage.getItem("token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(BASE_URL + UPDATE_PAYPAL_EMAIL,
        JSON.stringify(data), { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message)
        setIsLoading(false);
        navigate("/myprofile", { state: { menu: 1 } });
      } else if (response.data.status_code === 400 && response.data.status === false) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else {
        setIsLoading(false);
        toast.error(response.data.message)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset1();
    }
  };

  const onSubmitWithdraw = async (data) => {
    console.log("Withdraw Form Data:");
    setIsLoading(true)
    try {
      const token = localStorage.getItem("token");
      const headers = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const response = await axios.post(BASE_URL + WITHDRAW_MONEY,
        JSON.stringify(data), { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        toast.success(response.data.message)
        setIsLoading(false);
        navigate("/myprofile", { state: { menu: 1 } });
      } else if (response.data.status_code === 400 && response.data.status === false) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else {
        setIsLoading(false);
        toast.error(response.data.message)
      }
    } catch (error) {
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage =
          error.response.data.message ||
          "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset2();
    }
  };

  const withdrawalRequest = async () => {
    setIsLoading(true)
    const token = localStorage.getItem('token')
    try {
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };
      const response = await axios.get(BASE_URL + USER_WITHDRAWAL_MONEY_REQUEST, {
        headers: headers,
      });
      if (response.data.status_code === 200 && response.data.status === true) {
        setWithDrawalData(response.data.data);
        setIsLoading(false);
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        toast.error(response?.data?.error[0]);
        setIsLoading(false);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false
      ) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        setIsLoading(false);
      } else {
        toast.error(response.data.message);
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
        setIsLoading(false);
      } else {
        toast.error("An error occurred");
        setIsLoading(false);
      }
    }
  }
  useEffect(() => {
    setValue("paypal_email", userDetails?.user_details?.paypal_email);

    withdrawalRequest()
  }, [userDetails]);

  const handlePageChange = (pageNumber) => { setCurrentPage(pageNumber); };

  return (
    <>
      <Loader isLoading={isLoading} />

      <section>
        <Container>
          <div className="edit-profile-main ">
            <h3 className="main-head-pro">Wallet</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal d-flex">
                <div className="credit-inner-mg-pan">
                  <figure>
                    <img src={backi} alt="" />
                  </figure>
                  <div className="icon-cred-avail">
                    <img className="logo-plan" src={logcomp} alt="" />
                    <div className="cred-avl">
                      <p>Available Balance</p>
                      <h6>£ {userDetails?.user_details?.wallet}</h6>
                    </div>
                  </div>
                </div>
                <div className="cred-instruction w-100">
                  <div className="upper-head-w-logo cred-avl">
                    <h6 className="mb-3">Link Your Paypal Account: </h6>
                    <p>Status: {userDetails?.user_details?.paypal_email ?
                      <FontAwesomeIcon icon={faCircleCheck} />
                      :
                      <FontAwesomeIcon icon={faCircleXmark} />}
                    </p>
                    <p>Email address: {userDetails?.user_details?.paypal_email ? userDetails.user_details.paypal_email : 'N/A'} </p>

                  </div>

                  <div className="upper-btn-login-page text-start">
                    <button className="edit-butt" onClick={() => setHandlePaypalShow(true)}>
                      Update Email
                    </button>
                  </div>
                </div>
              </div>
              <div className="upper-btn-login-page text-start mt-4">
                <button className="edit-butt" onClick={() => setShow(true)}>
                  Withdraw
                </button>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <section>
        <Container>
          <div className="edit-profile-main mt-4">
            <h3 className="main-head-pro">WithDrawal History</h3>
            <div className="upper-form-main">
              <div className="form-main-name-detal">
                <div className="upper-my-tournament-table">
                  <table className="w-100 text-center">
                    <tr>
                      <th>Amount</th>
                      <th>Requested on</th>
                      <th>Updated on</th>
                      <th>Status</th>
                    </tr>
                    {withDrawalData && withDrawalData.map((withdraw, index) => (
                      <tr key={index}>
                        <td>£ {withdraw.amount}</td>
                        <td>{moment(withdraw.created_at).format('HH:mm DD-MM-YYYY')}</td>
                        <td>{withdraw.status === 'pending' ? '-' : moment(withdraw.updated_at).format('HH:mm DD-MM-YYYY')}</td>
                        <td className="text-capitalize">{withdraw.status}</td>
                      </tr>
                    ))}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </section>
      <Modal show={show} className="modal-logout" onHide={handleClose}>
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Withdraw Money</h2>
          </div>
          <div className="cred-instruction w-100">
            <div className="upper-head-w-logo d-flex">
              <h6>Withdrawal Instructions</h6>
              <img src={redlogo} alt="" />
            </div>
            <ul className="cred-list">
              <li><b>2% + 0.30p</b> withdraw fee for NON VIP members </li>
              <li><b>1% + 0.30p</b> withdraw fee for VIP members </li>
              <li>Any requests under <b>£10</b> will be charged <b>£1</b></li>
            </ul>
          </div>

          <Form onSubmit={handleSubmit2(onSubmitWithdraw)}>
            <Form.Group
              className="input-comn-styel-signup"
              controlId="formGroupEmail">
              <Form.Label className='label-clas-comon'>Enter Amount<span>*</span></Form.Label>
              <Form.Control
                type="text"
                {...register2('amount', {
                  required: "Amount is required",
                  pattern: {
                    value: /^[0-9]+$/,
                    message: "Please enter a valid integer.",
                  },
                  validate: (value) => {
                    const numberValue = parseFloat(value);
                    if (isNaN(numberValue) || numberValue < 1) {
                      return "Amount must be at least 1";
                    }
                    return true;
                  }
                })}
                className='input-comn-cls'
                placeholder="Enter Withdrawal Amount"
              />
              {errors2.amount && (
                <span className="text-danger">
                  {errors2.amount.message}
                </span>
              )}
            </Form.Group>
            <Button className='login-btn' type="submit">Submit</Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={handlePaypalShow} className="modal-logout" onHide={() => setHandlePaypalShow(false)}>
        <Modal.Header className="border-0 pb-0" closeButton></Modal.Header>
        <Modal.Body className="modal-padd">
          <div className="inner-ogout-btn">
            <h2>Link Your Paypal Account</h2>
          </div>
          <Form onSubmit={handleSubmit1(onSubmitPaypalEmail)}>
            <Form.Group
              className="input-comn-styel-signup"
              controlId="formGroupEmail">
              <Form.Label className='label-clas-comon'>PayPal Email Address<span>*</span></Form.Label>
              <Form.Control
                type="email"
                {...register1('paypal_email', {
                  required: "This Field is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Invalid email address",
                  }
                })}
                className='input-comn-cls'
                placeholder="Enter Your Email Address"
              />
              {errors1.paypal_email && (
                <span className="text-danger">
                  {errors1.paypal_email.message}
                </span>
              )}
            </Form.Group>
            <Button className='login-btn' type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Wallet;
