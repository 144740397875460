import React, { useState, useEffect } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import rpfil from "../Assets/Images/Background.png";
import sigimg from "../Assets/signup.png";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axiosClient from "../Services/axiosClient";
import { FILE_UPLOAD, UPDATE_USER_PROFILE, BASE_URL, IMAGE_BASE_URL } from "../API";
import { toast } from "react-toastify";
import logologin from "../Assets/Images/loginlogo.png";
import Button from "react-bootstrap/Button";
import Loader from "../components/Loader";
import axios from "axios"; // Make sure axios is imported
import userImage from '../Assets/user.png'

function Editprofile({ userDetails, getUserDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const {
    handleSubmit,
    formState: { errors },
    control,
    getValues,
    setValue,
    reset,
    register,
  } = useForm();

  useEffect(() => {
    setValue("name", userDetails.name);
    setValue("email", userDetails.email);
    setValue("player_id", userDetails.user_details.player_id);
    setValue("address", userDetails.user_details.address);
    setValue("country", userDetails.user_details.country);
    setValue("state", userDetails.user_details.state);
    setValue("city", userDetails.user_details.city);
    setValue("zip_code", userDetails.user_details.zip_code);
    setValue("service", userDetails.user_details.service);
    setValue("psn", userDetails.user_details.psn);
    setValue("xbox", userDetails.user_details.xbox);
    setValue("ea", userDetails.user_details.ea);
    setValue("ubisoft", userDetails.user_details.ubisoft);
    setValue("activision", userDetails.user_details.activision);
    setValue("discord", userDetails.user_details.discord);

  }, [userDetails]);

  const onSubmit = async (data) => {
    console.log('data::::::::::', data)
    setIsLoading(true);
    try {
      setValue("player_id", userDetails.user_details.player_id);
      data["player_id"] = userDetails.user_details.player_id;
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      const response = await axios.post(
        BASE_URL + UPDATE_USER_PROFILE,
        JSON.stringify(data),
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        setIsLoading(false);
        toast.success(response.data.message);
        getUserDetails();
      } else if (
        response.data.status_code === 400 &&
        response.data.status === false
      ) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        console.log('response.data::', response)
        toast.error(response.data.message);
      }
    } catch (error) {
      console.log('error::',)
      if (error.response && error.response.data) {
        setIsLoading(false);
        const errorMessage = error?.response?.data?.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        setIsLoading(false);
        toast.error("An error occurred");
      }
      reset();
    }
  };

  const loadFile = async (event) => {
    try {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];

      // Check if a file is selected
      if (!event.target.files || event.target.files.length === 0) {
        toast.error("Please select a file.");
        return;
      }
      const image = event.target.files[0];

      // Check if the selected file type is allowed
      if (!allowedTypes.includes(image.type)) {
        toast.error("Only JPEG, JPG, and PNG files are allowed.");
        return;
      }

      const formData = new FormData();

      formData.append('profile_picture', image);
      // formData.append('profile_picture', image.name);

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        'content-type': 'multipart/form-data',
        "Accept": "application/json",
      };

      const response = await axios.post(
        BASE_URL + FILE_UPLOAD,
        formData,
        { headers: headers }
      );

      if (response.data.status_code === 200 && response.data.status === true) {
        getUserDetails()
        setIsLoading(false);
        toast.success(response.data.message);
      } else if (response.data.status_code === 400 && response.data.status === false) {
        setIsLoading(false);
        toast.error(response?.data?.error[0]);
      } else if (
        response.data.status_code === 403 &&
        response.data.status === false) {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/login');
      } else {
        // console.log('response.data::',response)
        toast.error(response.data.message);
      }
    } catch (error) {
      setIsLoading(false);
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message || "An error occurred";
        toast.error(errorMessage);
      } else {
        toast.error("An error occurred");
      }
      reset(); // Assuming reset() function is defined elsewhere
    }
  }



  return (
    <>
      <Loader isLoading={isLoading} />
      <section>

        <div className="edit-profile-main">
          <h3 className="main-head-pro">Edit Profile</h3>
          <div className="upper-form-main d-flex">
            <div className="img-profile-main d-flex">
              <div className="profile-pic">
                <label className="-label" htmlFor="file">
                  <span className="glyphicon glyphicon-camera">
                    <i className="fas fa-camera"></i>
                  </span>
                </label>
                <Form.Control
                  id="file"
                  type="file"
                  name="profile_picture"
                  onChange={loadFile}
                />
                <figure className="profile-img">
                  <img src={userDetails?.user_details?.profile_picture ? IMAGE_BASE_URL + userDetails?.user_details?.profile_picture : userImage} alt="" />
                </figure>
              </div>
            </div>

            <div className="form-main-name-detal">
              <Form
                className="form-clas-login"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Row>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Username<span>*</span></Form.Label>
                      <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Username is required",
                          minLength: {
                            value: 2,
                            message:
                              "Username must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message: "Username cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              disabled={true}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your Username"
                            />
                            {errors?.name && (
                              <span className="text-danger">
                                {errors?.name.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupEmail">
                      <Form.Label className="label-clas-comon">Email<span>*</span></Form.Label>
                      <Controller
                        name="email"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(?!.*\s)([A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}|[^\s@]+@[^\s@]+\.[^\s@]+)$/i,
                            message: "Invalid email address",
                          },
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="email"
                              className="input-comn-cls"
                              placeholder="Enter Your Email"
                            />
                            {errors.email && (
                              <span className="text-danger">
                                {errors.email.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Player ID<span>*</span></Form.Label>
                      <Controller
                        name="player_id"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <>
                            <Form.Control
                              disabled={true}
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your Player Id"
                            />
                            {errors?.player_id && (
                              <span className="text-danger">
                                {errors?.player_id.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Address <span>*</span></Form.Label>
                      <Controller
                        name="address"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Address is required",
                          minLength: {
                            value: 2,
                            message:
                              "Address must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message: "Address cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/,
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your Address"
                            />
                            {errors?.address && (
                              <span className="text-danger">
                                {errors?.address.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">               
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Country<span>*</span></Form.Label>
                      <Controller
                        name="country"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Country name is required",
                          minLength: {
                            value: 2,
                            message:
                              "Country name must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message:
                              "Country name cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/,
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your Country Name"
                            />
                            {errors?.country && (
                              <span className="text-danger">
                                {errors?.country.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col> 
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">
                        State<span>*</span>
                      </Form.Label>
                      <Controller
                        name="state"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "State Name is required",
                          minLength: {
                            value: 2,
                            message:
                              "State Name must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message: "State Name cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/, // Regular expression to disallow leading and trailing white spaces
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your State Name"
                            />
                            {errors?.state && (
                              <span className="text-danger">
                                {errors?.state.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">
                        City<span>*</span>
                      </Form.Label>
                      <Controller
                        name="city"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "City Name is required",
                          minLength: {
                            value: 2,
                            message:
                              "City Name must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message: "City Name cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/, 
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your City Name"
                            />
                            {errors?.city && (
                              <span className="text-danger">
                                {errors?.city.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">
                        Zip Code<span>*</span>
                      </Form.Label>
                      <Controller
                        name="zip_code"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Zip Code is required",
                          minLength: {
                            value: 2,
                            message:
                              "Zip Code must be at least 2 characters long",
                          },
                          maxLength: {
                            value: 50,
                            message: "Zip Code cannot exceed 50 characters",
                          },
                          pattern: {
                            value: /^\S(.*\S)?$/, 
                            message: "White spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your Zip Code"
                            />
                            {errors?.zip_code && (
                              <span className="text-danger">
                                {errors?.zip_code.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col> 
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">
                        Gamer ID<span>*</span>
                      </Form.Label>
                      <Controller
                        name="service"
                        control={control}
                        defaultValue=""
                        rules={{
                          required: "Service is required",
                        }}
                        render={({ field }) => (
                          <>

                            <Form.Select aria-label="Default select example"   {...field}>
                              <option selected disabled>Select Gamer ID</option>
                              <option value="PSN">PSN</option>
                              <option value="Xbox Gamertag">Xbox Gamertag</option>
                              <option value="Activision">Activision</option>
                              <option value="Ubisoft">Ubisoft</option>
                              <option value="EA App">EA App</option>

                            </Form.Select>
                            {errors?.service && (
                              <span className="text-danger">
                                {errors?.service?.message}
                              </span>
                            )}
                          </>
                        )}
                      />

                    </Form.Group>

                  </Col> */}
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">PSN ID</Form.Label>
                      <Controller
                        name="psn"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.psn && (
                              <span className="text-danger">
                                {errors?.psn.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Xbox Gamertag</Form.Label>
                      <Controller
                        name="xbox"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.xbox && (
                              <span className="text-danger">
                                {errors?.xbox.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Activision ID</Form.Label>
                      <Controller
                        name="activision"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.activision && (
                              <span className="text-danger">
                                {errors?.activision.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Ubisoft ID</Form.Label>
                      <Controller
                        name="ubisoft"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.ubisoft && (
                              <span className="text-danger">
                                {errors?.ubisoft.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">EA ID</Form.Label>
                      <Controller
                        name="ea"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.ea && (
                              <span className="text-danger">
                                {errors?.ea.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6} className="comn-inpt-main">
                    <Form.Group className="" controlId="formGroupFullName">
                      <Form.Label className="label-clas-comon">Discord ID</Form.Label>
                      <Controller
                        name="discord"
                        control={control}
                        defaultValue=""
                        rules={{
                          pattern: {
                            value: /^\S*$/,
                            message: "Spaces are not allowed"
                          }
                        }}
                        render={({ field }) => (
                          <>
                            <Form.Control
                              {...field}
                              type="text"
                              className="input-comn-cls"
                              placeholder="Enter Your ID"
                            />
                            {errors?.ea && (
                              <span className="text-danger">
                                {errors?.discord.message}
                              </span>
                            )}
                          </>
                        )}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="upper-btn-login-page text-start mt-2">
                  <button className="edit-butt" type="submit">Save Changes</button>
                </div>
              </Form>
            </div>
          </div>
        </div>

      </section>
    </>
  );
}

export default Editprofile;
